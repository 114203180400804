import React from 'react'

function Display() {
    return (
    <div class="container">
    <h1 class="mb-5">Display & Position</h1>

    {/* Display None */}
    <p class="d-none">This text is hidden</p>

    <br /><br />

    {/* Display Inline */}
    <div class="d-inline">This text is inline</div>
    <div class="d-inline">This text is inline</div>

    <br /><br />

    {/* Display Block */}
    <span class="d-block">This span is block</span>
    <span class="d-block">This span is block</span>

    <br /><br />

    {/* Display Inline Block */}
    <span class="d-inline-block" style={{"Width": "500px"}}> This span is inline-block</span>
    <span class="d-inline-block">This span is inline-block</span>

    <br /><br />

    {/* Hide on small screens */}
    <div class="d-none d-md-block">This div is hidden on mobile</div>

    <br /><br />

    {/* Hide on medium screens and up */}
    <div class="d-block d-md-none">
        This div is hidden on medium screens and up
    </div>

    <br /><br />

    {/* POSITIONING */}

    {/* Position Static */}
    <p class="position-static">This text is static</p>

    <br /><br />

    <div
        class="position-relative"
        style={{"Height": "200px", "Width": "200px", "Background": "#ccc"}}
    >
        <p class="position-absolute start-0 bottom-0" style={{"top": "60px"}}>
        Hello
        </p>
    </div>

    <br /><br />

    {/* Position Fixed */}
    <p class="position-fixed">This text is fixed</p>

    <br /><br />

    <p class="fixed-top">This text is fixed top</p>
    <p class="fixed-bottom">This text is fixed bottom</p>

    <br /><br />

    {/* Sticky Top */}
    <p class="">This text is sticky top</p>

    <br /><br />

    {/* Filler Text */}
    <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
        perferendis voluptatibus eveniet nesciunt explicabo architecto
        voluptatem! Laudantium mollitia voluptate sit dolor! Maiores architecto
        iure placeat, deleniti iste doloribus velit quam magnam ex blanditiis
        itaque ipsum consectetur dolorem debitis optio temporibus facere error
        eaque nesciunt accusantium possimus. Dolorum assumenda molestias
        cupiditate exercitationem esse, nam inventore nesciunt eaque, ex
        reiciendis distinctio sequi optio sint at a nulla. Iure rem architecto
        voluptatem aliquam molestiae odit exercitationem vitae, debitis neque
        nostrum aperiam fugiat tempore magnam cum! Harum excepturi iusto iste
        porro ex cum optio magnam labore doloremque culpa possimus asperiores
        aliquid incidunt, hic, necessitatibus neque obcaecati aliquam. Aperiam,
        excepturi facere dolores temporibus corporis dicta atque iusto
        repudiandae debitis voluptas quasi magni quisquam sit veniam
        necessitatibus veritatis fugit dolorum rerum animi? Beatae facere ipsam
        distinctio. Minima unde tempore maiores aliquid sunt nihil dolorum,
        itaque alias, possimus sequi nobis! Commodi maxime, quos reprehenderit
        mollitia veritatis ipsa nobis quo numquam quis rem expedita temporibus
        laudantium nulla, exercitationem magnam, voluptates et? Voluptates,
        cumque laboriosam soluta saepe explicabo quisquam ex a voluptatum
        assumenda quia vel iusto reiciendis eaque provident! Eveniet numquam
        aut, rerum et perferendis praesentium, maiores eaque nihil earum
        voluptate dolores dolore blanditiis pariatur aliquid aspernatur
        consectetur! Minus, voluptatum autem veniam ducimus id earum similique
        alias cum impedit blanditiis, suscipit, tempora iusto nemo ea. Eum
        ducimus architecto dolorum fugit saepe nesciunt, fuga aspernatur ratione
        debitis quos sequi, nisi eius inventore enim magni id et perspiciatis
        reprehenderit, animi adipisci voluptates porro. Enim, error non! Aliquam
        sapiente maxime praesentium ullam possimus recusandae illum qui non amet
        dolorum earum error, provident, voluptas quas eveniet minus voluptatibus
        excepturi eaque laboriosam. Maxime, fugiat. Sed ipsum aliquam suscipit
        officiis excepturi omnis, optio possimus placeat laudantium quam facilis
        dolore voluptate aperiam expedita pariatur ipsa recusandae perferendis
        perspiciatis nesciunt quos earum praesentium dolores! Error expedita
        nulla inventore atque voluptates excepturi ea fuga optio blanditiis
        totam nesciunt saepe, voluptate minima voluptatem accusamus vero
        aliquid! Necessitatibus ut corporis illo praesentium, assumenda,
        perspiciatis quo magni similique, animi cupiditate iure. Corrupti
        reiciendis harum nobis, aperiam itaque dolores accusantium voluptates
        quos mollitia amet dignissimos inventore tempora laudantium, fuga
        repudiandae voluptate odit tenetur? Doloremque soluta rem a
        consequuntur, consectetur obcaecati. Quia hic architecto officiis
        sapiente dolore odit, illum corporis laboriosam. Quas quidem doloribus
        quaerat rerum est, ad repudiandae provident culpa reiciendis illum
        soluta nesciunt non iusto beatae earum accusamus maiores recusandae
        ducimus sint, minus consectetur? Natus beatae officiis molestiae
        explicabo excepturi, sapiente vitae provident aut consequatur ea atque
        velit laborum. Nulla amet sequi quisquam fugiat repellendus libero
        dolor. Quisquam voluptates quos sapiente est doloribus fugit, quam
        necessitatibus commodi? Ex, consectetur. Incidunt, explicabo quaerat
        iusto aliquid corporis cumque iure perferendis ipsam at velit vero nulla
        quisquam ab dicta odio illo! Numquam cupiditate consequatur ut,
        voluptatum quas excepturi quis nulla error maiores cum illo nesciunt
        voluptatem accusantium itaque iusto dolor modi! Mollitia eos culpa
        nostrum ducimus rem quasi animi tenetur reprehenderit velit nesciunt
        placeat, reiciendis eligendi saepe officiis nihil rerum odio ratione
        illo voluptatibus ipsa consequuntur dicta voluptatum impedit? Aperiam
        veritatis fugit totam. Labore.
    </p>
    </div>    
    )
}

export default Display